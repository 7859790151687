import React from 'react';

const ArrowDownIcon = () => {
  return (
    <div className="mx-auto shadow-inner" style={{
      width: 0,
      height: 0,
      borderLeft: "20px solid transparent", // Keeps the sides of the arrow transparent
      borderRight: "20px solid transparent", // Keeps the sides of the arrow transparent
      borderTop: "40px solid #e6b85c", // Change this color to your desired color (e.g., red)
    }}></div>
  );
};

const Hero = () => {
  return (
    <div className="flex-col pb-8 mb-10 items-center justify-center w-full h-full overflow-hidden bg-transparent">       
      <div className="text-center h-full w-full">
        <h1 className="my-4 shadow-inner text-4xl md:text-5xl lg:text-6xl font-bold leading-tight text-[#e6b85c]">
          Say Hello to Charlie
        </h1> 
        <h2 className="my-3 shadow-inner text-xl md:text-2xl lg:text-3xl font-bold leading-tight">
          The Salty Voice Assistant
        </h2>
        <p className="text-md mb-8 shadow-inner">
          Click below to wake her up...
        </p>
        <ArrowDownIcon />
      </div>
    </div>
  );
};

export default Hero;
